import { Center, Tooltip } from '@chakra-ui/react'
import { BentoBox } from '../BentoBox'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'

export const ReseauWireStock = () => (
    <Tooltip label="Ouvrir WireStock">
        <BentoBox
            size={'C1'}
            bgImage={'/aboutme/wirestock.png'}
            bgSize={'80%'}
            as="a"
            href="https://wirestock.io/quentin.dubois?content=gallery"
            target="_blank"
        />
    </Tooltip>
)

export const ReseauInstagram = () => (
    <Tooltip label="Ouvrir Instagram">
        <BentoBox
            size={'C1'}
            bgImage={'reseau/instagram.svg'}
            bgSize={'80%'}
            as="a"
            href="https://instagram.com/frenchoute"
            target="_blank"
        />
    </Tooltip>
)

export const ReseauYoutube = () => (
    <Tooltip label="Ouvrir YouTube">
        <BentoBox
            size={'C1'}
            bgImage={'/reseau/youtubemini.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.youtube.com/@frenchoute"
            target="_blank"
        />
    </Tooltip>
)

export const ReseauMalt = () => (
    <Tooltip label="Ouvrir Malt">
        <BentoBox
            size={'C1'}
            bgImage={'reseau/malt.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.malt.fr/profile/qdub"
            target="_blank"
        />
    </Tooltip>
)
export const ReseauWhatsApp = () => (
    <Tooltip label="Téléphone : +33 6 68 34 39 86">
        <BentoBox
            as="a"
            bgImage={'reseau/WhatsApp.svg'}
            size={'C1'}
            href={
                "whatsapp://send?abid=+33668343986&text=Bonjour! J'aimerais faire appel a vos services..."
            }
        ></BentoBox>
    </Tooltip>
)

export const ReseauLK = () => (
    <Tooltip label="Ouvrir LinkedIn">
        <BentoBox
            size={'C1'}
            bgColor={'#0076b2'}
            bgImage={'reseau/LinkedIn.svg'}
            bgSize={'80%'}
            as="a"
            href="https://www.linkedin.com/in/qdub/"
            target="_blank"
        />
    </Tooltip>
)

export const ReseauMail = () => (
    <Tooltip label="Mail : duboisq.pro@gmail.com">
        <BentoBox
            as="a"
            size={'C1'}
            href={
                "mailto:duboisq.pro@gmail.com?body=Bonjour! J'aimerais faire appel a vos services..."
            }
        >
            <EmailIcon fontSize={'32px'}></EmailIcon>
        </BentoBox>
    </Tooltip>
)

export const ReseauTel = () => (
    <Tooltip label="Téléphone : +33 6 68 34 39 86">
        <BentoBox
            as="a"
            size={'C1'}
            href={
                "sms:+33668343986?body=Bonjour! J'aimerais faire appel a vos services..."
            }
        >
            <PhoneIcon fontSize={'32px'}></PhoneIcon>
        </BentoBox>
    </Tooltip>
)

export const ReseauBPL = () => (
    <Tooltip label="Ouvrir BackPackLife.fr">
        <BentoBox
            size={'C2H1'}
            bgImage={'reseau/bpl.png'}
            bgSize={'90%'}
            as="a"
            href="https://www.backpacklife.fr"
            target="_blank"
        />
    </Tooltip>
)
