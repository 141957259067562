import {
    Box,
    Center,
    Heading,
    Grid,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
import { ModalProjet } from '../../Molecule/Modal/ModalProjet'
import { Ruban } from '../../Atom/Ruban'
import {
    TechnoAWS,
    TechnoArduino,
    TechnoC,
    TechnoCSharp,
    TechnoCss,
    TechnoDocker,
    TechnoFireBase,
    TechnoGCP,
    TechnoGrafana,
    TechnoIOTCore,
    TechnoJS,
    TechnoJava,
    TechnoKafka,
    TechnoLangChain,
    TechnoLinux,
    TechnoMeteor,
    TechnoMongoDb,
    TechnoMysql,
    TechnoNode,
    TechnoPhp,
    TechnoRaspberry,
    TechnoReact,
    TechnoScala,
    TechnoSpark,
    TechnoTS,
    TechnoUnity,
    TechnoWP,
} from '../../Molecule/TechnoBox'
import { BluredBox } from '../../Atom/BluredBox'
import { BottomBox } from '../../Atom/BottomBox'
export const Project = () => {
    const { language } = useContext(languageContext)
    const modalFenxmobility = useDisclosure()
    const modalMEC = useDisclosure()
    const modalPULSAR = useDisclosure()
    const modalTimelapse = useDisclosure()
    const modaleco = useDisclosure()
    const modalBigData = useDisclosure()
    const modalElec = useDisclosure()
    const modalJeuxvideo = useDisclosure()

    const modalIA = useDisclosure()
    const modalOrege = useDisclosure()
    const modalDoc = useDisclosure()

    return (
        <Box w={'100%'} id="project" pt={'100px'}>
            <SectionTitle withDecoration>
                {language === 'fr' ? (
                    <>Projets Réalisés</>
                ) : (
                    <>Projects already done</>
                )}
            </SectionTitle>
            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '700px',
                        lg: '1078px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(8, 1fr)',
                        lg: 'repeat(12, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(24, 70px)',
                        md: 'repeat(12, 70px)',
                        lg: 'repeat(8, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                >
                    <BentoBox
                        size={'C4H2'}
                        bgColor="#118ab2"
                        onClicAction={modalIA.onOpen}
                    >
                        <Ruban title={'Freelance'} bgColor={'#000'} />
                        <Center as="b" mt={'40px'}>
                            <Heading fontSize={'26px'}>
                                IA : ChatBot spécialisé
                            </Heading>
                        </Center>
                    </BentoBox>{' '}
                    <BentoBox
                        size={'C4H2'}
                        onClicAction={modalOrege.onOpen}
                        bgColor={'#EEF3F4'}
                        bgImage={'/projet/orege.png'}
                        bgSize={'60%'}
                    >
                        <Ruban title={'Freelance'} bgColor={'#000'} />
                    </BentoBox>
                    <BentoBox
                        size={'C4H2'}
                        onClicAction={modalDoc.onOpen}
                        bgColor="#06d6a0"
                    >
                        {' '}
                        <Center as="b" mt={'34px'}>
                            <Heading fontSize={'28px'}>
                                Gestionnaire de documents sécurisé
                            </Heading>
                        </Center>
                        <Ruban title={'Freelance'} bgColor={'#000'} />
                    </BentoBox>
                    <BentoBox
                        size={'C4'}
                        bgColor={'#EEF3F4'}
                        bgImage={'/projet/fenx.png'}
                        bgSize={'90%'}
                        onClicAction={modalFenxmobility.onOpen}
                    >
                        <Ruban title={'Freelance'} bgColor={'#000'} />
                    </BentoBox>
                    <BentoBox
                        size={'C4'}
                        bgColor={'#EEF3F4'}
                        bgImage={'/projet/mec.svg'}
                        bgSize={'80%'}
                        onClicAction={modalMEC.onOpen}
                    >
                        {' '}
                        <Ruban title={'Freelance'} bgColor={'#000'} />
                    </BentoBox>
                    <BentoBox
                        size={'C4'}
                        bgImage={'/projet/pulsar.jpg'}
                        bgSize={'cover'}
                        onClicAction={modalPULSAR.onOpen}
                    >
                        <BottomBox>
                            <Center>
                                <Text as="b" fontSize={'22px'}>
                                    Pulsar : la data en temps réel
                                </Text>
                            </Center>
                        </BottomBox>
                    </BentoBox>
                    <BentoBox
                        size={'C4'}
                        bgColor="#118ab2"
                        bgImage={'projet/timelapse.png'}
                        bgSize={'cover'}
                        onClicAction={modalTimelapse.onOpen}
                    >
                        <Ruban title={'Freelance'} bgColor={'#000'} />

                        <Box
                            bg={'#1F293766'}
                            backdropFilter={'blur(10px)'}
                            h={'calc(100% - 210px)'}
                            padding={'15px'}
                            paddingTop={'0px'}
                            borderRadius={'20px'}
                            mt={'200px'}
                            mx={'25px'}
                        >
                            <Center>
                                <Text as="b" fontSize={'26px'}>
                                    TimeLapse
                                </Text>
                            </Center>
                            <Center>
                                <Text as="b" fontSize={'26px'}>
                                    connecté
                                </Text>
                            </Center>
                        </Box>
                    </BentoBox>
                    <BentoBox
                        size={'C4H2'}
                        bgColor="#ef476f"
                        onClicAction={modalBigData.onOpen}
                    >
                        <BluredBox>
                            <Center>
                                <Text as="b" fontSize={'22px'}>
                                    Big Data
                                </Text>
                            </Center>
                            <Text fontSize={'16px'} textAlign={'center'}>
                                Collecte, traitement, consolidation et
                                restitution de la donnée.
                            </Text>
                        </BluredBox>
                    </BentoBox>
                    <BentoBox size={'C4H2'} onClicAction={modaleco.onOpen}>
                        <Ruban title={'Projet perso'} bgColor={'#000'} />
                        <Center as="b" mt={'34px'}>
                            <Heading fontSize={'32px'}>Site E-Commerce</Heading>
                        </Center>
                    </BentoBox>
                    <BentoBox
                        size={'C4H2'}
                        bgImage={'projet/electronic.jpg'}
                        bgSize={'cover'}
                        onClicAction={modalElec.onOpen}
                    >
                        <Ruban title={'Projet perso'} bgColor={'#000'} />

                        <Center as="b" mt={'30px'}>
                            <Heading fontSize={'40px'}>Électronique</Heading>
                        </Center>
                    </BentoBox>
                    <BentoBox
                        size={'C2H1'}
                        onClicAction={modalJeuxvideo.onOpen}
                    >
                        <Center as="b" mt={'4px'}>
                            Jeux Vidéos
                        </Center>
                    </BentoBox>
                    <BentoBox
                        size={'C2'}
                        bgImage={'projet/skeletoon.png'}
                        bgSize={'cover'}
                        onClicAction={modalJeuxvideo.onOpen}
                    ></BentoBox>
                    <BentoBox
                        size={'C1'}
                        bgImage={'projet/burgerpop.webp'}
                        bgSize={'cover'}
                        onClicAction={modalJeuxvideo.onOpen}
                    ></BentoBox>
                    <BentoBox
                        size={'C1'}
                        bg={'#22A922'}
                        bgImage={'projet/hungrypig.webp'}
                        bgSize={'contain'}
                        onClicAction={modalJeuxvideo.onOpen}
                    ></BentoBox>
                </Grid>
            </Center>
            {/**MODAL SPACE */}
            <ModalProjet
                title="Prototypage de Chatbot spécialisé"
                entreprise="Confidentiel"
                duree="2 mois"
                content={`Participation à la réalisation de prototype de chatbot spécialisé basé sur un corpus de documents métiers fournies<br>
                <br>
                ▫️ Réalisation des IHM d'interraction avec le modèles<br>
                ▫️ Industrialisation du RAG pour alimenter le corpus<br>
                `}
                isOpen={modalIA.isOpen}
                onClose={modalIA.onClose}
            >
                <TechnoLangChain />
                <TechnoReact />
                <TechnoFireBase />
                <TechnoAWS />
            </ModalProjet>

            <ModalProjet
                title="Modernisation et exploitation des données"
                entreprise="OREGE"
                duree="10 mois"
                content={`
                    Accompagnement dans la modernisation des outils de collecte, d'analyse et de restitution des données.<br>
                    L'objectif étant de collecter une grande quantité de donnée provenant de machines industrielles réparties sur plusieurs continents pour centraliser le monitoring en temps réel.<br>
                <br>
                ▫️ Analyse des besoins métiers<br>
                ▫️ Collecte et préparation de la données (AWS IOT CORE)<br>
                ▫️ Modélisation et stockage de la données (AWS SiteWise)<br>
                ▫️ Réalisation de Dashboard sur portail Sitewise et sur Grafana<br>
                ▫️ Industrialisation complète<br>
                `}
                isOpen={modalOrege.isOpen}
                onClose={modalOrege.onClose}
            >
                <TechnoAWS />
                <TechnoGrafana />
                <TechnoIOTCore />
            </ModalProjet>
            <ModalProjet
                title="Gestionnaire de fichiers"
                entreprise="AGEF Courtage"
                duree="---"
                content={`Accompagnement complet dans la création d'une solution d'herbergement et de partage de fichiers.<br>
                <br>
                ▫️ Analyse des besoins métiers<br>
                ▫️ Conception et réalisation de l'applciation cible prenant en compte les contraintes métiers liés à la sécurité des données.<br>
                ▫️ Application Serverless Cloud<br>
                
                `}
                isOpen={modalDoc.isOpen}
                onClose={modalDoc.onClose}
            >
                <TechnoReact />
                <TechnoTS />
                <TechnoFireBase />
                <TechnoGCP />
            </ModalProjet>
            <ModalProjet
                title="FenX mobility"
                entreprise="FenXmobility.com"
                duree="1 an"
                content={`Accompagnement complet dans la création de la solution FenX mobility.<br>
                FenX mobility est une entreprise Rennaise axée sur la location de vélo B2B & B2C.<br>
                <br>
                ▫️ Analyse des besoins métiers<br>
                ▫️ Réalisation et maintenance du site vitrine.<br>
                ▫️ Conception et réalisation d'une application de gestion de flote de vélo incluant une partie d'administration et un partie client (statistique et suivie d'usage).<br>
                ▫️ Application Serverless Cloud<br>
                `}
                isOpen={modalFenxmobility.isOpen}
                onClose={modalFenxmobility.onClose}
            >
                <TechnoReact />
                <TechnoTS />
                <TechnoFireBase />
                <TechnoWP />
            </ModalProjet>
            <ModalProjet
                title="MonEmprunt.com"
                entreprise="MonEmprunt.com"
                duree="2 ans et 7 mois (CDI) - 1 an et 6 mois (FreeLance)"
                content={`
                    J'ai intégré l'équipe MonEmprunt en tant que premier développeur titulaire pour conduire la transition et l'internalisation du savoir technique.<br>
                    <br>
                    Dans un contexte agile et d’amélioration continue proche des métiers, j'ai disposé d’un spectre d’action très large, me permettant de réaliser des tâches variées telles que :<br>
                    ▫️ Spécification des besoins métier, pour évolution et mise en place de nouvelles solutions<br>
                    ▫️ Développements techniques, que ce soit des corrections de bugs, évolution et développement from scratch de nouvelles solutions.<br>
                    ▫️ Développement principalement Front et intervention sur la partie Back API, bases de données et infrastructures.<br>
                    ▫️ Mise en place DevOps, infrastructure Full cloud Google (migration et abandon du "on premise").<br>
                    ▫️ Relation technique partenaires et prestataires.<br>
                    ▫️ Support technique interne pour les équipes métier incluant préparation et maintenance des outils informatiques hardware/software.<br>
                    `}
                isOpen={modalMEC.isOpen}
                onClose={modalMEC.onClose}
            >
                <TechnoReact />
                <TechnoTS />
                <TechnoGCP />
                <TechnoJava />
                <TechnoDocker />
                <TechnoMysql />
            </ModalProjet>
            <ModalProjet
                title="Pulsar : la data en temps réel"
                entreprise="CGI"
                duree="2 ans"
                content={`Développement d'une solution de traitement de data incluant toute la chaine de donnée, de la collecte à la restitution.<br>
                    <br>
                    Création du Proof of concept puis conception, architecture et développement de l'application.<br>
                    ▫️ Acquisition de donnée provenant plusieurs sources<br>
                    ▫️ Traitement sur technologie big data (Kafka,Spark Streaming) <br>
                    ▫️ Restitution sur client léger en temps réel (Meteor JS)<br>
                    <br>
                    Consolidation et mise en production projet:<br>
                    ▫️ Mise en place d'une plateforme d'intégration continu dans le cloud Azure ( Git, Jenkins)<br>
                    ▫️ Containerisation de l'ensemble des applications et déploiement via Docker<br>
                    ▫️ Déploiement de la solution sur plateforme Kubernetes Azure (AKS)<br>
                    ▫️ Travaux sur la sécurité, la mise a l'echelle et la robustesse de la solution<br>
                    ▫️ Formation de l'équipe et rôle de tech lead<br>
                    `}
                isOpen={modalPULSAR.isOpen}
                onClose={modalPULSAR.onClose}
            >
                <TechnoMeteor />
                <TechnoJS />
                <TechnoMongoDb />
                <TechnoSpark />
                <TechnoKafka />
                <TechnoScala />
            </ModalProjet>
            <ModalProjet
                title="TimeLapse connecté"
                entreprise="TRAIT DE LUMIERE"
                duree="4 mois"
                content={`
                    Développement de services web et de logiciels Java sur Raspberry Pi.<br>
                    Conception et développement d'un système de prise de vue type TimeLapse embarqué sur Raspberry Pi (ARM).<br>
                    <br>
                    Principales fonctionnalités :<br>
                    ▫️ Développement Java de l'application embarquée.<br>
                    ▫️ Automatisation des déploiements via un OS préconfiguré.<br>
                    ▫️ Prise en charge des clés 4G / wifi.<br>
                    ▫️ Utilisation d’un module Camera Raspberry.<br>
                    ▫️ Utilisation d'appareils photo Reflex avec configuration à distance via une interface web.<br>
                    ▫️ Développement d'un back office de monitoring, configuration et suivi des clichés en PHP.<br>
                    `}
                isOpen={modalTimelapse.isOpen}
                onClose={modalTimelapse.onClose}
            >
                <TechnoJava />
                <TechnoPhp />
                <TechnoJS />
                <TechnoCss />
                <TechnoRaspberry />
                <TechnoLinux />
            </ModalProjet>
            <ModalProjet
                title="E-Commerce vente de carte"
                entreprise="Personnel"
                duree="1 an"
                content={`
                    ▫️ Création d'une EI (entreprise individuelle).<br>
                    ▫️ Création d'une boutique en ligne sous WordPress.<br>
                    ▫️ Utilisation de WooCommerce et synchronisation automatique des produits avec eBay et traitement de paiements Stripe.<br>
                    ▫️ Développement d’un logiciel spécifique pour scanner les cartes recto-verso à la chaîne, faciliter le pricing via « recherche d'éléments similaires déjà en vente », et les mettre en vente.<br>
                    ▫️ Gestion de 2000 références uniques.<br>
                    ▫️ Logistique d'envoi des produits pour 500+ commandes.<br>
                    `}
                isOpen={modaleco.isOpen}
                onClose={modaleco.onClose}
            >
                <TechnoWP />
                <TechnoNode />
                <TechnoJS />
            </ModalProjet>
            <ModalProjet
                title="Big Data"
                entreprise="CGI"
                duree="10 mois "
                content={`Dans un contexte agroalimentaire pour une entreprise européenne majeure.<br>
                    ▫️ Analyse du besoin<br>
                    ▫️ Développement de module de collecte, de traitement et de restitution des informations de traçabilités produit au sein d'une plateforme Big data.<br>
                    `}
                isOpen={modalBigData.isOpen}
                onClose={modalBigData.onClose}
            >
                <TechnoSpark />
                <TechnoJava />
                <TechnoScala />
                <TechnoKafka />
            </ModalProjet>
            <ModalProjet
                title="Électronique"
                entreprise="Personnel"
                duree="3 ans"
                content={`Pendant plusieurs années, j'ai travailler a la conception de systèmes électroniques, en particulier :<br>
                    ▫️ Fabrication d'une Harpe Laser : recherche, code, développement<br>
                    ▫️ Système domotique multiple contrôlable en bluetooth, wifi, 4G, ZigBee<br>
                    `}
                isOpen={modalElec.isOpen}
                onClose={modalElec.onClose}
            >
                <TechnoC />
                <TechnoRaspberry />
                <TechnoArduino />
            </ModalProjet>
            <ModalProjet
                title="Jeux Vidéo"
                entreprise="Personnel"
                duree="4 ans"
                content={`Le développement de jeux vidéo a été au centre de mes premiers projets.<br>
                    J'ai réalisé une multitude de prototypes d'applications basées sur des concepts originaux ou en reproduction de concepts existants.<br>
                    Exemple : Tower Defence, Match3, infinity run avec génération procédurale, puzzle game etc..<br>
                    <br>
                    ▫️ Conception et développement en C# et JS avec Unity3D <br>
                    ▫️ Gestion, édition et création d'Asset (graphisme, sprite, 2D/3D, audio, shaders etc.)<br>
                    ▫️ Déploiement sur client web, client lourd PC/MAC et sur les stores iOS & Android<br>
                    `}
                isOpen={modalJeuxvideo.isOpen}
                onClose={modalJeuxvideo.onClose}
            >
                <TechnoUnity />
                <TechnoJS />
                <TechnoCSharp />
            </ModalProjet>
        </Box>
    )
}
