import { Box, Center, Grid, Tooltip } from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import {
    ReseauLK,
    ReseauMalt,
    ReseauWhatsApp,
    ReseauMail,
    ReseauTel,
} from '../../Molecule/ReseauBox'
export const Contact = () => {
    const { language } = useContext(languageContext)

    return (
        <Box w={'100%'} id="contact" pt={'100px'}>
            <SectionTitle withDecoration>
                {language === 'fr' ? <>Prenez contact !</> : <>Contact me</>}
            </SectionTitle>

            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '340px',
                        lg: '340px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(4, 1fr)',
                        lg: 'repeat(4, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(1, 70px)',
                        md: 'repeat(1, 70px)',
                        lg: 'repeat(1, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                    mb={'150px'}
                >
                    {/** Bloc 1  */}
                    <ReseauLK />
                    <ReseauMail />
                    <ReseauWhatsApp />
                    <ReseauTel />
                </Grid>
            </Center>
        </Box>
    )
}
