import {
    Box,
    Center,
    Heading,
    Grid,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { languageContext } from '../../LanguageContext/LanguageContext'
import { BentoBox } from '../../Molecule/BentoBox'
import { SectionTitle } from '../../Atom/SectionTitle'
export const Welcome = () => {
    const { language } = useContext(languageContext)

    return (
        <Box w={'100%'} id="welcome" pt={'100px'}>
            <Center>
                <Heading
                    color={useColorModeValue('#F1F2F3', '#000')}
                    fontSize={{ base: '30px', sm: '40px', md: '45px' }}
                >
                    Quentin Dubois{' '}
                </Heading>
            </Center>
            <SectionTitle withDecoration>Développeur logiciel</SectionTitle>

            <Center>
                <Grid
                    w={{
                        base: '340px',
                        md: '700px',
                        lg: '700px',
                    }}
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(8, 1fr)',
                        lg: 'repeat(8, 1fr)',
                    }}
                    gridTemplateRows={{
                        base: 'repeat(8, 70px)',
                        md: 'repeat(4, 70px)',
                        lg: 'repeat(4, 70px)',
                    }}
                    gap={4}
                    color={'#F1F2F3'}
                >
                    {/** Bloc 1  */}
                    <BentoBox
                        size={'C4'}
                        bgImage="profil1.jpg"
                        bgPosition={'center'}
                        bgSize={'cover'}
                        transform={'scale(0.8)'}
                        _hover={{}}
                    />

                    <BentoBox size={'C4'}>
                        <Text
                            fontSize={'16px'}
                            textAlign={'center'}
                            mt={'20px'}
                        >
                            Avec <b>8 ans d’expérience</b> en tant que
                            développeur dans différents types d’entreprises, mes
                            missions m’ont apporté une réelle vision d’ensemble
                            de la <b>réalisation de projet</b>.<br></br>
                            <br></br> Depuis toujours, mon objectif a été de
                            découvrir et d’apprendre sans jamais m’enfermer
                            techniquement, et ainsi devenir un{' '}
                            <b>développeur généraliste</b>.
                        </Text>
                    </BentoBox>
                </Grid>
            </Center>
        </Box>
    )
}
